const style = {
    span:{
        marginBottom: '10px'
    }
}

export const TopicObjects = () => {
        const topics = {
            mru: {
                section1:()=>{
                    return(
                    <div className="mb-1 row align-items-center">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/mru/p-1.jpg'} alt=""/>
                        </div>
                        <div className="col-lg-7">
                            <p className="section-title pr-5"><span className="pr-2">Aprendamos sobre</span></p>
                            <h1 className="mb-4">Movimiento Rectilineo Uniforme</h1>
                            <p>Imagina que eres un astronauta en la Estación Espacial Internacional. Estás arreglando unos 
                                paneles solares averiados, cuando de pronto, al presionar, tu destornillador sale disparado 
                                de tus manos. Si no lo atrapas a tiempo, el destornillador estará viajando por el espacio en 
                                línea recta y a velocidad constante, a menos que algo se interponga en su camino. 
                                Esto sucede porque la herramienta se mueve con movimiento rectilíneo uniforme, o MRU.
                            </p>
                            <p>El MRU se define el movimiento en el cual un objeto se desplaza en línea 
                                recta, en una sola dirección, recorriendo distancias iguales en el mismo 
                                intervalo de tiempo, manteniendo en todo su movimiento una velocidad 
                                constante y sin aceleración.
                                Recuerda que la velocidad es un vector, entonces, al ser constante, 
                                no varía ni su magnitud, ni su dirección de movimiento.
                            </p>
                        </div>
                    </div>
                    )
                },
                section2:() => {
                    return(
                        <div className="mt-5 row align-items-center">
                        <div className="col-lg-5">
                            <h1 className="mb-4">Condiciones del MRU</h1>
                            <p>
                            En física, el movimiento rectilíneo uniforme puede ser observado dentro de un plano, en 
                            un eje de coordenadas, donde el movimiento es en línea recta desplazándose hacia una 
                            dirección. Es uniforme porque las variables de velocidad y tiempo en la recta es 
                            constante.
                            </p>
                            <p>
                            Las fórmulas del movimiento rectilíneo uniforme contempla tres incógnitas: 
                            <br/>Velocidad constante (V), Distancia (d) y Tiempo (t).
                            </p>
                            <p>
                                <b>Velocidad</b>: La velocidad es el cambio de posición de un objeto con respecto al tiempo.
                            </p>
                            <p>
                                <b>Distancia</b>: se refiere a la trayectoria que recorre un objeto desde un punto A hasta un punto B, la longitud entre estos dos puntos se denomina distancia.	 
                            </p>
                            <p>
                                <b>Tiempo</b>: El tiempo es una magnitud física con la que se mide la duración o separación de acontecimientos.
                            </p>
                        </div>
                        <div className="col-lg-7">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/lessons/mru/p-2.jpg'} alt=""/>
                        </div>
                    </div>  
                    )
                },
                section3: () => {
                    return(
                        <div className="mt-5 row">
                            <div className="col">
                                <h1 className="mb-4">Ejemplo del MRU</h1>

                                <p>Un tren sale de París a las 6 hs y llega a las 8 hs a Lyon. Su recorrido es en 
                                    línea recta. La distancia entre la estación de París y la estación de Lyon es 
                                    de 400 km. El tren siempre va a la misma velocidad, sin acelerar ni frenar hasta 
                                    llegar a destino. ¿A qué velocidad va el tren?
                                </p>
                                <p>
                                    <h3><strong>Datos:</strong><br/></h3>
                                    <div style={style.span}><strong>Distancia</strong> =  400 km<br/></div> 
                                    <div style={style.span}><strong>Tiempo</strong> = 8hs - 6hs = 2hs <br/></div>
                                </p>
                                <p>
                                    <h3><strong>Solucion:</strong><br/></h3>
                                    <div style={style.span}><strong>Empleamos</strong> v = d/t<br/></div> 
                                    <div style={style.span}><strong>400km/2hs</strong> = 200 km/h<br/></div>
                                    <div style={style.span}><strong>Respuesta:</strong> El tren va a 200 km/h <br/></div>
                                </p>

                                <hr/>

                                <p>La ruta desde mi casa a la casa de mi amigo es una línea recta. Siempre que lo 
                                    visito voy en mi coche a una velocidad de 20 kilómetros por hora, sin aumentar 
                                    ni disminuir la velocidad hasta que llego allí. Tardo media hora en llegar.
                                </p>
                                <p>
                                    <h3><strong>Datos:</strong><br/></h3>
                                    <div style={style.span}><strong>Velocidad</strong> = 20 km/h<br/></div> 
                                    <div style={style.span}><strong>Tiempo</strong> = 1/2 h <br/></div>
                                </p>
                                <p>
                                    <h3><strong>Solucion:</strong><br/></h3>
                                    <div style={style.span}><strong>Empleamos</strong> d = d.t<br/></div> 
                                    <div style={style.span}><strong>20 km/h / 1/2 h</strong> = 10 km<br/></div>
                                    <div style={style.span}><strong>Respuesta:</strong> La casa de mi amigo se encuentra a 10km de distancia <br/></div>
                                </p>

                            </div>
                        </div>
                    )
                },
            },
            ln:{
                section1:()=>{
                    return(
                        <div className="mb-1 row align-items-center">
                            <div className="col-lg-5">
                                <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/lessons/leydenewton/newton.jpg'} alt=""/>
                            </div>
                            <div className="col-lg-7">
                                <p className="section-title pr-5"><span className="pr-2">Aprendamos sobre</span></p>
                                <h1 className="mb-4">Leyes de newton</h1>
                                <h3>¿Cuáles son las Leyes de Newton?</h3>
                                <p>
                                Las leyes de Newton son tres principios que sirven para describir el movimiento de 
                                los cuerpos, basados en un sistema de referencias inerciales (fuerzas reales con 
                                velocidad constante).
                                Las tres leyes de Newton son:
                                <ul style={{margin:"20px"}}>
                                    <li>Primera ley o ley de la inercia.</li>
                                    <li>Segunda ley o ley fundamental de la dinámica.</li>
                                    <li>Tercera ley o principio de acción y reacción.</li>
                                </ul>
                                </p>
                                <p>
                                    Estas leyes que relacionan la fuerza, la velocidad y el movimiento de los cuerpos 
                                    son la base de la mecánica clásica y la física. Fueron postuladas por el físico y 
                                    matemático inglés Isaac Newton, en 1687.
                                </p>
                            </div>
                        </div>
                        )
                },
                section2:() => {
                    return(
                        <div className="mt-5 row align-items-center">
                        <div className="col-lg-5">
                            <h1 className="mb-4">¿Qué términos están relacionados con las leyes de Newton para poder entenderlas?</h1>
                            <p>
                                Para entender las leyes de Newton, primero hay que conocer tres conceptos esenciales 
                                que le dan sentido a las teorías del científico inglés.
                                <ul style={{margin:"20px"}}>
                                    <li style={{margin:"10px"}}><b>Fuerza:</b> se trata de una determinada acción que permite que un objeto se mueva o, 
                                    incluso, que cambie su forma. Dicha acción puede ser levantar, halar, empujar o arrastrar 
                                    un cuerpo.</li>
                                    <li style={{margin:"10px"}}><b>Movimiento:</b> hace referencia al cambio de posición de un objeto a lo largo del 
                                    tiempo, partiendo de un punto de referencia.</li>
                                    <li style={{margin:"10px"}}><b>Aceleracion:</b> se trata del cambio de velocidad que puede experimentar un cuerpo 
                                    u objeto en movimiento.</li>
                                </ul>
                            </p>
                        </div>
                        <div className="col-lg-7">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/lessons/leydenewton/newton-sit.jpg'} alt=""/>
                        </div>
                    </div>  
                    )
                },
                section3: () => {
                    return(<>
                        <div className="mt-5 row">
                            <div className="col-5">
                                <h1 className="mb-4">Las leyes</h1>

                                <p>A continuacion se presentan las 3 leyes:
                                </p>
                                <p>
                                    <h3><strong>Primera Ley</strong><br/></h3>
                                    
                                </p>
                                <p>
                                   La ley de la inercia o primera ley postula que un cuerpo permanecerá en reposo o en movimiento recto con una velocidad constante, a menos que se aplique una fuerza externa.
                                    Dicho de otro modo, no es posible que un cuerpo cambie su estado inicial (sea de reposo o movimiento) a menos que intervengan una o varias fuerzas.
                                    Por ejemplo: Un hombre deja su auto estacionado en la puerta de su casa. Ninguna fuerza actúa sobre el auto. Al día siguiente, el auto sigue allí. 

                                </p>

                                <hr/>
                            </div>
                            <div className="col-lg-7">
                                <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/lessons/leydenewton/ley-1.jpg'} alt=""/>
                            </div>
                        </div>
                        <div className="mt-5 row">
                            <div className="col-lg-7">
                                <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/leydenewton/ley-2.jpg'} alt=""/>
                            </div>
                            <div className="col-5">
                                <p>
                                    <h3><strong>Segunda Ley</strong><br/></h3>                                   
                                </p>
                                <p>
                                La ley fundamental de la dinámica, segunda ley de Newton o ley fundamental postula que la fuerza neta que es aplicada sobre un cuerpo es proporcional a la aceleración que adquiere en su trayectoria.
                                Un ejemplo simple de la aplicación de esta Segunda Ley de Newton ocurre cuando empujamos un objeto pesado. Estando el objeto en quietud, o sea, con aceleración igual a cero, podemos poner en movimiento el objeto ejerciendo sobre él una fuerza que venza la inercia y que le imprima una aceleración determinada.
                                </p>

                                <hr/>
                            </div>
                            
                        </div>
                        <div className="mt-5 row">
                            <div className="col-5">
                                <p>
                                    <h3><strong>Tercera Ley</strong><br/></h3>                                   
                                </p>
                                <p>
                                Tercera ley de Newton: principio de acción y reacción
                                El postulado de la tercera ley de Newton dice que toda acción genera una reacción igual, pero en sentido opuesto.
                                Ejemplos de la tercera ley de Newton
                                Si una bola de billar golpea a otra, sobre la segunda se ejerce la misma fuerza que sobre la primera.
                               
                               </p>

                                <hr/>
                            </div>
                            <div className="col-lg-7">
                                <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/leydenewton/ley-3.jpg'} alt=""/>
                            </div>
                        </div>
                        
                    </>)
                },
            },
            mruv:{
                section1:()=>{
                    return(
                    <div className="mb-1 row align-items-center">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/mruv/tren.jpg'} alt=""/>
                        </div>
                        <div className="col-lg-7">
                            <p className="section-title pr-5"><span className="pr-2">Aprendamos sobre</span></p>
                            <h1 className="mb-4">Movimiento Rectilineo Uniformemente Variado</h1>
                            <p>El movimiento rectilíneo uniformemente variado o MRUV es un movimiento que ocurre sobre una línea recta con aceleración constante. En el MRUV la aceleración es constante, nunca va a cambiar, siempre es la misma. Mucha atención, lo constante es la aceleración, no la velocidad.
                            </p>
                            <p>La ecuación que describe el MRUV es: vf = vi + a . t <br/>
                                <b>Donde:</b>
                                <ul>
                                    <li><b>vf</b> es la velocidad final</li>
                                    <li><b>vi</b> es la velocidad incial</li>
                                    <li><b>a</b> es la aceleracion</li>
                                    <li><b>t</b> es el tiempo</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    )
                },
                section2:() => {
                    return(
                        <div className="mt-5 row align-items-center">
                        <div className="col-lg-5">
                            <h1 className="mb-4">Formulas del MRUV</h1>
                            <p>
                            La aceleración indica la variación de la velocidad por unidad de tiempo.
                            Por ejemplo, tenemos un auto que parte del reposo (v0 = 0 m/s) y avanza con una aceleración constante de 2 m/s2. Este valor de la aceleración, podemos expresarlo de la siguiente manera:
                            </p>
                        </div>
                        <div className="col-lg-7">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/mruv/formula-mruv.png'} alt=""/>
                        </div>
                    </div>  
                    )
                },
                section3: () => {
                    return(
                        <div className="mt-5 row">
                            <div className="col">
                                <h1 className="mb-4">Ejemplo del MRUV</h1>

                                <p>Supongamos que un automovil parte desde el reposo (vi = 0) y acelera a 2 m/s2 durante
                                    5 segundos. Queremos encontrar la velocidad final.
                                </p>
                                <p>
                                    <h3><strong>Datos:</strong><br/></h3>
                                    <div style={style.span}><strong>Velocidad inicial</strong> =  0<br/></div> 
                                    <div style={style.span}><strong>Aceleracion</strong> = 2 m/s2<br/></div>
                                    <div style={style.span}><strong>Tiempo</strong> = 5s<br/></div>
                                </p>
                                <p>
                                    <h3><strong>Solucion:</strong><br/></h3>
                                    <div style={style.span}><strong>Empleamos</strong> vf = vi + a . t<br/></div> 
                                    <div style={style.span}><strong>0 + (2)(5)</strong> = 10 m/s<br/></div>
                                    <div style={style.span}><strong>Respuesta:</strong> La velocidad final es 10 m/s <br/></div>
                                </p>

                                <hr/>
                            </div>
                        </div>
                    )
                },
            },
            tv:{
                section1:()=>{
                    return(
                    <div className="mb-1 row align-items-center">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/tirovertical/tiro-1.jpg'} alt=""/>
                        </div>
                        <div className="col-lg-7">
                            <p className="section-title pr-5"><span className="pr-2">Aprendamos sobre</span></p>
                            <h1 className="mb-4">Tiro Vertical</h1>
                            <p>En física, el tiro vertical o lanzamiento vertical es un tipo de movimiento rectilíneo uniformemente acelerado (m.r.u.a.) o movimiento rectilíneo uniformemente variado (m.r.u.v.).
                            </p>
                            <p>
                            Se denomina así a un tipo de movimiento que es resultado de arrojar un objeto verticalmente hacia arriba (o hacia abajo) desde cierta altura H. Una vez lanzado, el cuerpo asciende durante cierto tiempo y luego desciende en caída libre, con una aceleración igual al valor de la gravedad. En general, cuando se estudian los tiros verticales, no se toma en cuenta ningún tipo de fuerza de roce con el aire.
                            </p>
                            <p>Al igual que la caída libre, el lanzamiento vertical suele ser de particular interés dentro de la mecánica clásica.</p>
                        </div>
                    </div>
                    )
                },
                section2:() => {
                    return(
                        <div className="mt-5 row align-items-center">
                        <div className="col-lg-6 p-2">
                            <h1 className="mb-4">Características del tiro vertical</h1>
                            <p>
                            Puede decirse, de este modo, que el cuerpo lanzado en un tiro vertical sube y luego baja, regresando al punto de partida. Cuando el cuerpo alcanzó la altura máxima, la velocidad resulta nula. En ese instante, el cuerpo deja de subir e inicia su descenso. El tiempo que el cuerpo demora en llegar a la altura máxima resulta idéntico al tiempo que tarda en volver a su punto de partida.
                            </p>
                            <p>
                            Es importante destacar que existen diversas ecuaciones que permiten medir diferentes magnitudes vinculadas al tiro vertical. Estas ecuaciones trabajan con variables como la velocidad inicial, la altura y la aceleración.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/tirovertical/tiro-2.jpg'} alt=""/>
                        </div>
                    </div>  
                    )
                },
                section3: () => {
                    return(
                        <div className="mt-5 row">
                            <div className="col">
                                <h1 className="mb-4">Ejemplo del Tiro Vertical</h1>

                                <p>
                                Un proyectil se lanza verticalmente hacia arriba con una velocidad inicial de 25 M/S. ¿Cuánto tiempo tarda en llegar hacia su punto de velocidad máxima?
                                </p>
                                <p>
                                    <h3><strong>Solucion:</strong><br/></h3>
                                    <div style={style.span}>Parte de los 25 M/S, y pierde en cada segundo 9,81. Por lo tanto, tardará 2,54 segundos en llegar al suelo.
</div>
                                </p>
                                <hr/>
                            </div>
                        </div>
                    )
                },
            },
            cl:{
                section1:()=>{
                    return(
                    <div className="mb-1 row align-items-center">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL + 'img/lessons/caidalibre/formula.png'} alt=""/>
                        </div>
                        <div className="col-lg-7">
                            <p className="section-title pr-5"><span className="pr-2">Aprendamos sobre</span></p>
                            <h1 className="mb-4">Caida Libre</h1>
                            <p>En la caída libre un objeto cae verticalmente desde cierta altura H despreciando cualquier tipo de rozamiento con el aire o cualquier otro obstáculo. Se trata de un movimiento rectilíneo uniformemente acelerado (m.r.u.a.) o movimiento rectilíneo uniformemente variado (m.r.u.v.) en el que la aceleración coincide con el valor de la gravedad. En la superficie de la Tierra, la aceleración de la gravedad se puede considerar constante, dirigida hacia abajo, se designa por la letra g y su valor es de 9'8m/s2 (a veces se aproxima por 10 m/s2).
                            </p>
                            <p>
                            Para estudiar el movimiento de caída libre normalmente utilizaremos un sistema de referencia cuyo origen de coordenadas
                            </p>
                        </div>
                    </div>
                    )
                },
                section3: () => {
                    return(
                        <div className="mt-5 row">
                            <div className="col">
                                <h1 className="mb-4">Ejemplo de Caida libre</h1>

                                <p>
                                Desde un edificio se deja caer una pelota, que tarda 8 segundos en llegar al piso. ¿con que velocidad impacta la pelota contra el piso?
                                </p>
                                <p>
                                    <h3><strong>Solucion:</strong><br/></h3>
                                    <div style={style.span}>Avanza a una aceleración de 9,81 M/S2 durante 8 segundos, o sea que impacta a una velocidad de 78 M/S.</div>
                                </p>
                                <hr/>
                            </div>
                        </div>
                    )
                },
            },
    
        }
        return topics
}