import { About, Blog, Class, Facilities, Header, Team } from "../components"


export const HomePage = () => {
    return(
        <>
        <Header/>
        <Facilities/>
        <About/>
        <Class/>
        <Team/>
        </>
    )
}