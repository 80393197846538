import { HeaderTwo } from "../components"
import { Gallery } from "../components/Gallery"

export const GalleryPage = () => {
    return(
        <>
        <HeaderTwo title={'Galeria'}/>
        <Gallery/>
        </>
    )
}