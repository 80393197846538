import { TopicObjects } from "../helpers/TopicsObject"

export const TopicThird = (theme) => {
    const topics = TopicObjects() 
    return(
    <div className="container-fluid py-5">
        <div className="container">
            
            
            {topics.mruv.section1()}

            {topics.mruv.section2()}

            {topics.mruv.section3()}

        </div>
    </div>
    )
}