import { Link } from "react-router-dom"

export const AboutTwo = () => {
    return(
        <div className="container-fluid py-5">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/about-3.png'} alt=""/>
                </div>
                <div className="col-lg-7">
                    <p className="section-title pr-5"><span className="pr-2">Quienes Somos</span></p>
                    <h1 className="mb-4">Física Facil: Aprende sin limites</h1>
                    <p>A través de la educación, las mentes se expanden, se fomenta el pensamiento crítico y se cultivan habilidades que son esenciales para el éxito en la vida.</p>
                    <div className="row pt-2 pb-4">
                        <div className="col-6 col-md-8">
                            <ul className="list-inline m-0">
                                <li className="py-2 border-top border-bottom"><i className="fa fa-check text-primary mr-3"></i>Educativo</li>
                                <li className="py-2 border-bottom"><i className="fa fa-check text-primary mr-3"></i>Didactico</li>
                                <li className="py-2 border-bottom"><i className="fa fa-check text-primary mr-3"></i>Gratis</li>
                            </ul>
                        </div>
                    </div>
                    <Link to="/topics" className="btn btn-primary mt-2 py-2 px-4">Lecciones</Link>
                </div>
            </div>
            <div className="row align-items-center mt-5">
                <div className="col-lg-7">
                    <h1 className="mb-4">Educacion para todos</h1>
                    <p>En nuestro compromiso con la difusión del conocimiento y la democratización de la educación, nuestra página web tiene como objetivo principal proporcionar recursos educativos de alta calidad de manera completamente gratuita. Creemos en derribar barreras de acceso al aprendizaje, permitiendo que cualquier persona, en cualquier lugar del mundo, pueda explorar y comprender los fascinantes principios de la física. Nos esforzamos por cultivar una comunidad global de aprendices apasionados, brindando contenido interactivo, explicaciones claras y recursos complementarios para enriquecer la experiencia de aprendizaje. Al eliminar las barreras económicas, aspiramos a empoderar a individuos de diversas procedencias para que exploren el vasto y asombroso universo de la física, fomentando así una comprensión más profunda y compartida de los fundamentos científicos que nos rodean.</p>
                </div>
                <div className="col-lg-5">
                    <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/about-4.jpg'} alt=""/>
                </div>
            </div>
        </div>
    </div>
    )
}