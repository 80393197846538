import { Routes, Route} from 'react-router'
import { FisicaRoutes } from '../fisica/routes/FisicaRoutes'


export const AppRouter = () => {

    return (
        <Routes>

            <Route path="/*" element={
                
                <FisicaRoutes/>
            
            }/>
            
        </Routes>
    )
}
