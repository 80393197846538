import { TopicObjects } from "../helpers/TopicsObject"

export const TopicFour = () => {

    const topics = TopicObjects()   
    return(
    <div className="container-fluid py-5">
        <div className="container">
            
            
            {topics.tv.section1()}
            {topics.tv.section2()}
            {topics.tv.section3()}

        </div>
    </div>
    )
}