import {Navbar, Footer} from "../fisica/components"

export const AppTheme = ({children}) => {
    return(
        <>
        <Navbar/>
        
        {children}

        <Footer/>
        </>
    )
}