
export const Footer = () => {
    
    const style = {
        aFirst:{fontSize: '40px', lineHeight: '40px'},
        aSecond:{width: '38px', height: '38px'},
        div:{borderTop: '1px solid rgba(23, 162, 184, .2)'}
    }
    
    return(
        <div className="container-fluid bg-secondary text-white mt-5 py-5 px-sm-3 px-md-5">
        <div className="row pt-5">
            <div className="col-lg-4 col-md-6 mb-5">
                <a href="" className="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0" style={style.aFirst}>
                    <img className="mb-3 mr-2" src={process.env.PUBLIC_URL + 'img/logo.png'} alt="" />
                    <span className="text-white">FisicaFacil</span>
                </a>
                <p>Esta pagina fue creada por estudiantes de la Unidad Educativa Instituto La Salle en Barquisimeto como parte de un proyecto de grado</p>
                <div className="d-flex justify-content-start mt-4">
                    <a className="btn btn-outline-primary rounded-circle text-center mr-2 px-0"
                        style={style.aSecond} href="https://www.instagram.com/lasallebqto/?hl=es" target="_blank"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
                <h3 className="text-primary mb-4">Contáctanos</h3>
                <div className="d-flex">
                    <h4 className="fa fa-map-marker-alt text-primary"></h4>
                    <div className="pl-3">
                        <h5 className="text-white">Dirección</h5>
                        <p>Carrera 14 entre calles 28 y 29</p>
                    </div>
                </div>
                <div className="d-flex">
                    <h4 className="fa fa-envelope text-primary"></h4>
                    <div className="pl-3">
                        <h5 className="text-white">Email</h5>
                        <p>fisicafacil@gmail.com</p>
                    </div>
                </div>
                <div className="d-flex">
                    <h4 className="fa fa-phone-alt text-primary"></h4>
                    <div className="pl-3">
                        <h5 className="text-white">Telefono</h5>
                        <p>+58-412-543-8882</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-5">
                <img src={process.env.PUBLIC_URL + 'img/footer.png'} alt="" />
            </div>
        </div>
        <div className="container-fluid pt-5" style={style.div}>
            <p className="m-0 text-center text-white">
                &copy; <a className="text-primary font-weight-bold" href="#">Fisica Facil</a>. Todos los derechos reservados. Diseñado
                por
                <a className="text-primary font-weight-bold" href="https://htmlcodex.com"> HTML Codex</a>
            </p>
        </div>
    </div>
    )
}