import { Link } from "react-router-dom"

export const Blog = () => {
    return(
        <div className="container-fluid pt-5">
        <div className="container">
            <div className="text-center pb-2">
                <p className="section-title px-5"><span className="px-2">Lecciones</span></p>
                <h1 className="mb-4">Empieza a aprender</h1>
            </div>
            <div className="row pb-3">
                <div className="col-lg-4 mb-4">
                    <div className="card border-0 shadow-sm mb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/mru.jpg'} alt="MRU"/>
                        <div className="card-body bg-light text-center p-4">
                            <h4 className="">Movimiento Rectilineo Uniforme</h4>
                            <div className="d-flex justify-content-center mb-3">
                                <small className="mr-3"><i className="fa fa-user text-primary"></i> Admin</small>
                                <small className="mr-3"><i className="fa fa-folder text-primary"></i> Web Design</small>
                                <small className="mr-3"><i className="fa fa-comments text-primary"></i> 15</small>
                            </div>
                            <p>El MRU se define el movimiento en el cual un objeto se desplaza en línea recta, en una sola dirección, recorriendo distancias iguales en el mismo intervalo de tiempo, manteniendo en todo su movimiento una velocidad constante y sin aceleración.</p>
                            <Link to="/topic1" className="btn btn-primary px-4 mx-auto my-2">Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card border-0 shadow-sm mb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/ley-newton.jpg'} alt=""/>
                        <div className="card-body bg-light text-center p-4">
                            <h4 className="">Leyes de newton</h4>
                            <div className="d-flex justify-content-center mb-3">
                                <small className="mr-3"><i className="fa fa-user text-primary"></i> Admin</small>
                                <small className="mr-3"><i className="fa fa-folder text-primary"></i> Web Design</small>
                                <small className="mr-3"><i className="fa fa-comments text-primary"></i> 15</small>
                            </div>
                            <p>Las leyes de Newton son tres principios que analizan cómo hay fuerzas que actúan sobre los objetos para que estos se muevan. </p>
                            <Link to="/topic2" className="btn btn-primary px-4 mx-auto my-2">Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card border-0 shadow-sm mb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/mruv.jpg'} alt=""/>
                        <div className="card-body bg-light text-center p-4">
                            <h4 className="">Movimiento Rectilineo Uniformemente Variado</h4>
                            <div className="d-flex justify-content-center mb-3">
                                <small className="mr-3"><i className="fa fa-user text-primary"></i> Admin</small>
                                <small className="mr-3"><i className="fa fa-folder text-primary"></i> Web Design</small>
                                <small className="mr-3"><i className="fa fa-comments text-primary"></i> 15</small>
                            </div>
                            <p>El movimiento rectilíneo uniformemente variado o MRUV es un movimiento que ocurre sobre una línea recta con aceleración constante. En el MRUV la aceleración es constante, nunca va a cambiar, siempre es la misma. Mucha atención, lo constante es la aceleración, no la velocidad.</p>
                            <Link to="/topic3" className="btn btn-primary px-4 mx-auto my-2">Acceder</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pb-3">
                <div className="col-lg-4 mb-4">
                    <div className="card border-0 shadow-sm mb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/tiro-v.jpeg'} alt="Tiro Vertical"/>
                        <div className="card-body bg-light text-center p-4">
                            <h4 className="">Tiro Vertical</h4>
                            <div className="d-flex justify-content-center mb-3">
                                <small className="mr-3"><i className="fa fa-user text-primary"></i> Admin</small>
                                <small className="mr-3"><i className="fa fa-folder text-primary"></i> Web Design</small>
                                <small className="mr-3"><i className="fa fa-comments text-primary"></i> 15</small>
                            </div>
                            <p>En física, el tiro vertical o lanzamiento vertical es un tipo de movimiento rectilíneo uniformemente acelerado (m.r.u.a.) o movimiento rectilíneo uniformemente variado (m.r.u.v.).</p>
                            <Link to="/topic4" className="btn btn-primary px-4 mx-auto my-2">Acceder</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card border-0 shadow-sm mb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/caida-libre.jpeg'} alt=""/>
                        <div className="card-body bg-light text-center p-4">
                            <h4 className="">Caída Libre</h4>
                            <div className="d-flex justify-content-center mb-3">
                                <small className="mr-3"><i className="fa fa-user text-primary"></i> Admin</small>
                                <small className="mr-3"><i className="fa fa-folder text-primary"></i> Web Design</small>
                                <small className="mr-3"><i className="fa fa-comments text-primary"></i> 15</small>
                            </div>
                            <p>En la caída libre un objeto cae verticalmente desde cierta altura H despreciando cualquier tipo de rozamiento con el aire o cualquier otro obstáculo</p>
                            <Link to="/topic5" className="btn btn-primary px-4 mx-auto my-2">Acceder</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}