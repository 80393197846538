import { Link, NavLink } from "react-router-dom"

export const Navbar = () => {
    
    
    
    return(

        <div className="container-fluid bg-light position-relative shadow">
        <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0 px-lg-5">  
            <NavLink to="/" className="navbar-brand font-weight-bold text-secondary" style={{fontSize:"50px"}}>
                <img className="mb-3 mr-2" src={process.env.PUBLIC_URL + 'img/logo.png'} alt="" />
                <span className="text-primary">FísicaFácil</span>
            </NavLink>
            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div className="navbar-nav font-weight-bold mx-auto py-0">
                    <Link to="/" className="nav-item nav-link">Inicio</Link>
                    <Link to="/about" className="nav-item nav-link">Nosotros</Link>
                </div>
                <Link to="/topics" class="btn btn-primary px-4">Lecciones Aquí</Link>
            </div>
        </nav>
    </div>
    )
}