import { Link } from "react-router-dom"

export const Class = () => {
    return(
        <div className="container-fluid pt-5">
        <div className="container">
            <div className="text-center pb-2">
                <p className="section-title px-5"><span className="px-2">Popular</span></p>
                <h1 className="mb-4">Juegos</h1>
            </div>
            <div className="row">
                <div className="col-lg-4 mb-5">
                    <div className="card border-0 bg-light shadow-sm pb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/class-1.jpg'}alt=""/>
                        <div className="card-body text-center">
                            <h4 className="card-title">M.R.U. Elige los correctos</h4>
                            <p className="card-text">Escoge las opciones correctas para ganar</p>
                        </div>
                        <div className="card-footer bg-transparent py-4 px-5">
                        </div>
                        <Link to="/juego" className="btn btn-primary px-4 mx-auto mb-4">Jugar</Link>
                        
                    </div>
                </div>
                <div className="col-lg-4 mb-5">
                    <div className="card border-0 bg-light shadow-sm pb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/class-2.jpg'} alt=""/>
                        <div className="card-body text-center">
                            <h4 className="card-title">M.R.U. Movimiento</h4>
                            <p className="card-text">Selecciona los conceptos correctos para ganar</p>
                        </div>
                        <div className="card-footer bg-transparent py-4 px-5">
                        </div>
                        <Link to="/juego2" className="btn btn-primary px-4 mx-auto mb-4">Jugar</Link>
                    </div>
                </div>
                <div className="col-lg-4 mb-5">
                    <div className="card border-0 bg-light shadow-sm pb-2">
                        <img className="card-img-top mb-2" src={process.env.PUBLIC_URL + 'img/class-3.jpg'} alt=""/>
                        <div className="card-body text-center">
                            <h4 className="card-title">Fisica - Equivalencias e instrumentos</h4>
                            <p className="card-text">Pon a prueba tus conocimientos sobre fisica </p>
                        </div>
                        <div className="card-footer bg-transparent py-4 px-5">
                        </div>
                        <Link to="/juego3" className="btn btn-primary px-4 mx-auto mb-4">Jugar</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}