import { TopicObjects } from "../helpers/TopicsObject"

export const TopicFirst = (theme) => {
    const topics = TopicObjects() 
    return(
    <div className="container-fluid py-5">
        <div className="container">
            
            
            {topics.mru.section1()}

            {topics.mru.section2()}

            {topics.mru.section3()}

        </div>
    </div>
    )
}