export const Facilities = () => {
    
    const style = {padding: '30px'}
    
    return(
        <div className="container-fluid pt-5">
        <div className="container pb-3">
            <div className="row">
                <div className="col-lg-4 col-md-6 pb-1">
                    <div className="d-flex bg-light shadow-sm border-top rounded mb-4" style={style}>
                        <i className="flaticon-050-fence h1 font-weight-normal text-primary mb-3"></i>
                        <div className="pl-4">
                            <h4>Contenido Interactivo</h4>
                            <p className="m-0">Estos recursos permiten a los estudiantes mejorar significativamente la comprensión de los principios fundamentales.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 pb-1">
                    <div className="d-flex bg-light shadow-sm border-top rounded mb-4" style={style}>
                        <i className="flaticon-022-drum h1 font-weight-normal text-primary mb-3"></i>
                        <div className="pl-4">
                            <h4>Navegación Intuitiva</h4>
                            <p className="m-0">La información en la página web está organizada de manera lógica y fácil de navegar. </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 pb-1">
                    <div className="d-flex bg-light shadow-sm border-top rounded mb-4" style={style}>
                        <i className="flaticon-017-toy-car h1 font-weight-normal text-primary mb-3"></i>
                        <div className="pl-4">
                            <h4>Estudia donde sea</h4>
                            <p className="m-0">Accede a contenido educativo siempre que tengas conexión a internet cuando y donde quieras.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}