import { HomePage } from "./fisica/pages/HomePage";
import { AboutPage } from "./fisica/pages/AboutPage";
import { AppRouter } from "./router/AppRouter";


function App() {
  return (
    <AppRouter/>
  );
}

export default App;
