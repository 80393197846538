import { Link } from "react-router-dom"

export const GamePage = () => {
    return(
        <>
        <div className="container-fluid py-5">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/class-1.jpg'} alt=""/>
                    <a href="https://www.cerebriti.com/juegos-de-ciencias/mru2" target="_blank" className="btn btn-primary mt-3 px-4 mx-auto mb-4">Jugar</a>
                </div>
                <div className="col-lg-7">
                    <p className="section-title pr-5"><span className="pr-2">Juego #1</span></p>
                    <h1 className="mb-4">M.R.U.- Elige los correctos</h1>
                    <p>Repasa la leccion de M.R.U. en nuestra plataforma para conseguir las respuestas correctas, pulsa aqui abajo para ir a la leccion.</p>
                    <Link to="/topic1" className="btn btn-primary mt-2 py-2 px-4">Leccion M.R.U</Link>
                    <div className="row pt-2 pb-4">
                        <div className="col-6 col-md-8">
                            <ul className="list-inline m-0">
                                <li className="py-2 border-top border-bottom"><i className="fa fa-check text-primary mr-3"></i>Educativo</li>
                                <li className="py-2 border-bottom"><i className="fa fa-check text-primary mr-3"></i>Didactico</li>
                                <li className="py-2 border-bottom"><i className="fa fa-check text-primary mr-3"></i>Gratis</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}