import { Link } from "react-router-dom"

export const About = () => {
    return(
        <div className="container-fluid py-5">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <img className="img-fluid rounded mb-5 mb-lg-0" src={process.env.PUBLIC_URL +'img/about-1.jpg'} alt=""/>
                </div>
                <div className="col-lg-7">
                    <p className="section-title pr-5"><span className="pr-2">Quiénes Somos</span></p>
                    <h1 className="mb-4">Física Fácil: Conectando Mentes Curiosas con el Conocimiento </h1>
                    <p>Buscamos brindar contenido educativo de calidad sobre los conceptos básicos de la física que enseñan en las instituciones de manera gratuita</p>
                    <div className="row pt-2 pb-4">
                        <div className="col-6 col-md-4">
                            <img className="img-fluid rounded" src={process.env.PUBLIC_URL + 'img/about-2.jpg'} alt=""/>
                        </div>
                        <div className="col-6 col-md-8">
                            <ul className="list-inline m-0">
                                <li className="py-2 border-top border-bottom"><i className="fa fa-check text-primary mr-3"></i>Educativo</li>
                                <li className="py-2 border-bottom"><i className="fa fa-check text-primary mr-3"></i>Didactico</li>
                                <li className="py-2 border-bottom"><i className="fa fa-check text-primary mr-3"></i>Gratis</li>
                            </ul>
                        </div>
                    </div>
                    <Link to="/topics" className="btn btn-primary mt-2 py-2 px-4">Lecciones</Link>
                </div>
            </div>
        </div>
    </div>
    )
}