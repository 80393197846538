import { Routes, Route, Navigate } from "react-router"
import { HomePage } from "../pages/HomePage"
import { AboutPage } from "../pages/AboutPage"
import { TeamPage } from "../pages/TeamPage"
import { GalleryPage } from "../pages/GalleryPage"
import { GamePage } from "../pages/GamePage"
import { GamePageTwo } from "../pages/GamePageTwo"
import { GamePageThree } from "../pages/GamePageThree"

import { TopicFirst } from "../topics/pages/TopicFirst"
import { TopicTwo } from "../topics/pages/TopicTwo"
import { TopicThird } from "../topics/pages/TopicThird"
import { TopicFour } from "../topics/pages/TopicFour"
import { TopicFifth } from "../topics/pages/TopicFifth"
import { TopicPage } from "../topics/pages/TopicPage"



export const FisicaRoutes = () => {
    return(
        <Routes>
            
            <Route path="/" element={<HomePage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/team" element={<TeamPage/>}/>
            <Route path="/gallery" element={<GalleryPage/>}/>
            <Route path="/juego" element={<GamePage/>}/>
            <Route path="/juego2" element={<GamePageTwo/>}/>
            <Route path="/juego3" element={<GamePageThree/>}/>
            <Route path="/topics" element={<TopicPage/>}/>
            <Route path="/topic1" element={<TopicFirst/>}/>
            <Route path="/topic2" element={<TopicTwo/>}/>
            <Route path="/topic3" element={<TopicThird/>}/>
            <Route path="/topic4" element={<TopicFour/>}/>
            <Route path="/topic5" element={<TopicFifth/>}/>
            <Route path="/*" element={<Navigate to="/"/>}/>

        </Routes>
    )
}