import { HeaderTwo, AboutTwo } from "../components"


export const AboutPage = () => {
    return(
        <>
        <HeaderTwo title={'Nosotros'}/>
        <AboutTwo/>
        </>
    )
}