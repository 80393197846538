import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppTheme } from './theme/AppTheme';
import { HashRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <AppTheme>
        <App />
      </AppTheme>
    </HashRouter>  
  </React.StrictMode>
);

