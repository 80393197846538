export const Header = () =>{
    return(
        <div className="container-fluid bg-primary px-0 px-md-5 mb-5">
        <div className="row align-items-center px-3">
            <div className="col-lg-6 text-center text-lg-left">
                <h4 className="text-white mb-4 mt-5 mt-lg-0">Bienvenido!</h4>
                <h1 className="display-3 font-weight-bold text-white">Aprender física nunca habia sido tan fácil</h1>
                <p className="text-white mb-4">Con nuestro material didáctico buscamos ayudar al aprendizaje de los 
                temas básicos de física para preparar a nuestros jóvenes para el bachillerato.</p>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
                <img className="img-fluid mt-5" src={process.env.PUBLIC_URL + 'img/header.png'} alt=""/>
            </div>
        </div>
    </div>
    )
}