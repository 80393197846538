export const Team = () => {

    const style = {
        
        div:{borderRadius: '100%'},
        a:{ width: '38px', height: '38px'} 

    }

    return (
        <div className="container-fluid pt-5">
            <div className="container">
                <div className="text-center pb-2">
                    <p className="section-title px-5"><span className="px-2">Equipo</span></p>
                    <h1 className="mb-4">Conocenos</h1>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-6 text-center team mb-5">
                        <div className="position-relative overflow-hidden mb-4" style={style.div}>
                            <img className="img-fluid w-100" src={process.env.PUBLIC_URL + 'img/team.jpeg'} alt="" />
                            <div
                                className="team-social d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
                                <a className="btn btn-outline-light text-center mr-2 px-0" style={style.a}
                                    href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-outline-light text-center mr-2 px-0" style={style.a}
                                    href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-light text-center px-0" style={style.a}
                                    href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <i></i>
                    </div>
                    <div className="d-flex col-md-6 col-lg-6 text-center align-items-center justify-content-center team mb-5">
                        <h4>¡Hola! Somos Daniel, Silva, Sebastián y Jesús, un equipo apasionado por la física del Colegio La Salle. Hemos creado esta página web con el objetivo de hacer que la física básica sea accesible y emocionante para todos. ¡Bienvenidos a nuestro espacio de aprendizaje!</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}